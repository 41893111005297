
import { Component, Vue } from 'vue-property-decorator';
import { request, IRequestMethod } from '@mobro/request';
import { Ifunction, Iobject } from '@mobro/libs/es/type';
import { Message } from 'element-ui';
import { ElForm } from 'element-ui/types/form';

@Component({})
export default class ArticleCreate extends Vue {
    private udaForm = {
        udaId: '',
        udaName: '',
        udaDesp: '',
    };

    onSubmit(formName: string) {
        (this.$refs[formName] as ElForm)?.validate(valid => {
            if (valid) {
                request({
                    method: IRequestMethod.POST,
                    url: '/api/uda/create',
                    params: { ...this.udaForm, series: this.udaForm.udaId.split('_')[0] },
                }).then((res: Iobject) => {
                    if (res) {
                        Message.success('创建埋点成功！');
                    } else {
                        Message.error('创建埋点失败了');
                    }
                });
            } else {
                console.warn('请检查你的参数列表');

                return false;
            }
        });
    }

    private udaRule = {
        udaId: [
            {
                required: true,
                message: '埋点ID必填',
                trigger: 'blur',
            },
            {
                validator: (rule: Iobject, value: string, callback: Ifunction) => {
                    if (!/^[A-Z]{2,9}_[SC]_[0-9]{1,9}$/.test(value)) {
                        callback(new Error('埋点ID格式不正确'));
                    }
                    callback();
                },
                trigger: 'blur',
            },
        ],
        udaName: [
            {
                required: true,
                message: '埋点名称必填',
                trigger: 'blur',
            },
            {
                validator: (rule: Iobject, value: string, callback: Ifunction) => {
                    // eslint-disable-next-line no-useless-escape
                    if (!/^[\u4e00-\u9fa5]{2,9}(\-[\u4e00-\u9fa5]{1,9})*(\-[0-9]{1,9})?$/.test(value)) {
                        callback(new Error('埋点ID格式不正确'));
                    }
                    callback();
                },
                trigger: 'blur',
            },
        ],
    };
}
